.side-menu-fixed .side-menu li a.active {
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
}
.side-menu-fixed .side-menu {
  .icon-hover {
    opacity: 0;
    float: right;
  }
  a:hover {
    .icon-hover {
      opacity: 1;
    }
  }
  .disable {
    background: #484954;
    color: #ffffff;
    a:hover,
    a:focus {
      .icon-hover {
        opacity: 0;
      }
      cursor: auto;
      background: #484954;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.ti-plus-sub-nav {
  &:before {
    content: '\e622' !important;
  }
}

.ti-subtract {
  &:before {
    content: '\e61a' !important;
  }
}

.nav-main-item {
  margin-left: -10px;
}

.icon-nav-item {
  margin-left: -10px;
}

.slide-menu .side-menu-fixed .right-nav-text {
  opacity: 1 !important;
}

.invisiable {
  i {
    visibility: visible !important;
  }
  .nav-main-item {
    display: none;
    opacity: 0;
  }
}
