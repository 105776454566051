body {
  font-size: 0.6rem;
}
.brand-logo-mini {
  display: none !important;
}

.nav-item {
  margin-left: 0px !important;
  margin-right: 2px !important;
}

.ico-toggle {
  font-size: 15px;
}
.btn-toggle-group {
  min-width: 20px !important;
}
.mb-mobile-btn-toggle {
  margin-left: 3px !important;
  margin-right: 3px;
}
.title-language {
  font-size: $medium-size;
}

.flag-icon {
  width: 15px;
  height: 15px;
}

.user-avatar {
  img {
    width: 18px !important;
    height: 18px !important;
    margin-right: 20px !important;
  }
}
.msg-reset-pass {
  margin-top: 5px;
  margin-bottom: 20px;
}
.button {
  font-size: $medium-size !important;
  letter-spacing: 0rem !important;
}

.dropdown-item {
  font-size: $min-size !important;
  padding: 5px !important;
}

.content-wrapper {
  padding-left: 0px;
}

.fixed-top {
  position: relative;
}
.form-control {
  font-size: 8px;
}
h5 {
  font-size: $max-size;
}
h6 {
  font-size: $medium-size;
}
.account-info {
  font-size: $medium-size;
}
.group-label-details {
  font-size: $medium-size;
}
.nav-item:hover {
  z-index: 2;
}

.dropdown-menu {
  min-width: 100px;
}

.nav-main-screen {
  display: none !important;
}

.nav-mb-screen {
  display: block !important;
}

.flag-icon-item {
  width: 18px;
  height: 18px;
}

.title-language {
  font-size: $min-size;
}

.expanded-sidebar {
  width: 218px;
}

.side-menu-fixed .right-nav-text {
  visibility: visible !important;
}

.invisiable {
  i {
    visibility: visible !important;
  }
  .nav-main-item {
    display: block !important;
    opacity: 1 !important;
  }
}

.modal-config .body-config {
  left: 10%;
}

.apexcharts-data-labels {
  display: none;
  rect {
    display: none;
  }
}

.bar-chart-value-min-max {
  top: 10%;
  right: 50px;
}

.bar-number-chart-details {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding-top: 12px;
  font-size: 10px;
}

.apx-legend-position-right{
  bottom: 10px !important;
  z-index: 22;
  top: auto !important;
  right: 0px !important;
}

.apexcharts-menu-icon{
  display: none;
}