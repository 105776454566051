li.job-card {
  list-style: none;
  position: relative;
  font-size: 12px;
  b {
    font-size: 12px;
  }
}
.job-schedule-status {
  padding-left: 20px;
}
.job-status {
  color: #28a745;
  &.job-status-pending,
  &.job-status-fetch,
  &.job-status-reprocessing {
    color: #ffc107;
  }
  &.job-status-completed,
  &.job-status-processing {
    color: #28a745;
  }
  &.job-status-suspended,
  &.job-status-canceled {
    color: #343a40;
  }
  &.job-status-error {
    color: #dc3545;
  }
}
.job-management {
  .react-datepicker-wrapper {
    margin-top: 0px;
  }
  .job-list-cards {
    display: flex;
    flex-wrap: wrap;
    &.job-card-sorting {
      .job-card {
        &::before {
          display: none;
        }
        .card-arrows {
          display: none;
        }
      }
    }
  }
  .job-card {
    position: relative;
    list-style: none;
    width: 310px;
    margin: 30px 25px;
    font-size: 14px;
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .job-card-handle {
      font-size: 1rem;
      cursor: move;
    }
    .dropdown-toggle-split.btn.btn-none {
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .job-card {
    position: relative;
    list-style: none;
    // width: 250px;
    // margin: 30px 25px;
    button {
      &:disabled,
      &.disabled {
        cursor: not-allowed;
      }
    }
    font-size: 14px;
    &.job-card-dragging {
      border: 1px solid #ebffeb;
      .card-header,
      .card-body,
      .card-footer {
        background-color: #ebffeb;
      }

      &::before {
        display: none;
      }
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &:last-child {
      .card-arrows {
        display: none !important;
      }
    }

    .card-arrows {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border: solid 1px #28a745;
      border-style: solid;
      top: 48%;
      right: -48px;
      width: 49px;
      background: #28a745;
      border-right: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        margin-right: -10px;
        width: 0;
        height: 0;
        border-top: solid 8px #00800000;
        border-left: solid 8px #28a745;
        border-right: solid 8px transparent;
        border-bottom: solid 8px transparent;
        margin-top: -8px;
      }
    }
    &.right-card {
      .card-arrows {
        position: absolute;
        display: block;
        content: '';
        border-color: transparent;
        border-style: solid;
        top: 100%;
        right: 50%;
        width: 10px;
        height: 0px;
        margin-top: 25px;
        background: #28a745;
        &::before {
          content: '';
          right: -2px;
          position: absolute;
          height: 27px;
          background: #28a745;
          width: 2px;
          top: -26px;
          border-bottom-right-radius: 2px;
        }
        &::after {
          content: '';
          position: absolute;
          left: -2px;
          margin-right: -10px;
          width: 0;
          height: 27px;
          margin-top: -8px;
          border: solid 1px #28a745;
          background: #28a745;
          top: 7px;
        }
      }
    }
    &.newline-card {
      &::before {
        content: '';
        top: -8px;
        left: 50%;
        margin-left: -7px;
        border: solid 2px #28a745;
        position: absolute;
        width: 9px;
        border-top: solid 8px #28a745;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
      }
    }
    .card-header {
      background-color: white;
      color: #222;
      cursor: move;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .card-body {
      border-bottom: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 15px 10px 10px 10px;
    }

    .job-name {
      color: #222;
      font-size: 1rem;
    }
    .job-status {
      // color: #28a745;
      display: inline-block;
      text-transform: capitalize;
    }
  }
}
@media only screen and (max-width: 600px) {
  .job-management .job-card {
    &.right-card {
      .card-arrows {
        background: transparent;
        &::before {
          top: -26px;
          height: 65px;
        }
        &::after {
          display: none;
          left: 0px;
          height: 35px;
        }
      }
    }
  }
}

.info-drop {
  right: 5px !important;
}
