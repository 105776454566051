.geteway-group {
  margin-top: 20px;
  padding-top: 10px;
}

.sub-from {
  padding: 0px 30px 10px 30px;
}

.btn-toggle-type {
  float: right;
  margin-left: -35px;
  margin-top: 6px;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 2;

  .icon-show-password {
    font-size: 20px;
    color: #70706d;
  }
}

.btn-submit {
  padding-top: 20px;
  margin-bottom: 50px;
}


.gateway-form{
  .card{
    box-shadow: none;
  }
}