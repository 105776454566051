$max-size: 13px;
$medium-size: 10px;
$min-size: 8px;

.dropdown-menu {
  min-width: 216px;
}

.nav-mb-screen {
  display: none !important;
}

.es-lang {
  @media (max-width: 1800px) {
    .dropdown-toggle {
      font-size: 1rem !important;
    }
    .title-language {
      font-size: 1rem !important;
    }
  }

  @media (max-width: 1750px) {
    .dropdown-toggle {
      font-size: 1rem !important;
      letter-spacing: 0.5px;
    }
    .title-language {
      font-size: 1rem !important;
    }
    .es-lang {
      .dropdown-toggle {
        letter-spacing: 0px;
      }
    }
  }

  @media (max-width: 1705px) {
    .dropdown-toggle {
      font-size: 0.95rem !important;
      letter-spacing: 0px;
    }
    .title-language {
      font-size: 0.95rem !important;
    }
    .es-lang {
      .dropdown-toggle {
        letter-spacing: 0px;
      }
    }
  }

  @media (max-width: 1650px) {
    .dropdown-toggle {
      font-size: 1rem !important;
      letter-spacing: -0.38px;
    }
    .title-language {
      font-size: 1rem !important;
    }
  }

  @media (max-width: 1600px) {
    .dropdown-toggle {
      font-size: 0.95rem !important;
      letter-spacing: -0.38px;
    }
    .title-language {
      font-size: 0.95rem !important;
    }
    .es-lang {
      .dropdown-toggle {
        letter-spacing: -0.38px;
      }
    }
  }

  @media (max-width: 1530px) {
    .dropdown-toggle {
      font-size: 0.9rem !important;
    }
    .title-language {
      font-size: 0.9rem !important;
    }
    .es-lang {
      .dropdown-toggle {
        letter-spacing: -0.38px;
      }
    }
  }

  @media (max-width: 1470px) {
    .dropdown-toggle {
      font-size: 0.8rem !important;
    }
    .title-language {
      font-size: 0.8rem !important;
    }
    .es-lang {
      .dropdown-toggle {
        letter-spacing: -0.38px;
        padding: 5px 3px !important;
      }
    }
  }

  @media (max-width: 1360px) {
    .dropdown-toggle {
      font-size: 0.75rem !important;
      padding: 5px 3px !important;
      letter-spacing: -0.3px;
    }
    .title-language {
      font-size: 0.75rem !important;
    }
    .text-md-left,
    .text-md-right {
      font-size: $medium-size;
    }
  }

  @media (max-width: 1280px) {
    .dropdown-toggle {
      font-size: 0.56rem !important;
      padding: 5px 3px !important;
      letter-spacing: -0.48px;
    }
    .title-language {
      font-size: 0.7rem !important;
    }
    .text-md-left,
    .text-md-right {
      font-size: $medium-size;
    }
    .fullscreen {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .dropdown-toggle {
      font-size: 0.5rem !important;
      padding: 5px 3px !important;
      letter-spacing: -0.48px;
    }
    .title-language {
      font-size: 0.5rem !important;
    }
    .text-md-left,
    .text-md-right {
      font-size: $medium-size;
    }
    .flag-icon {
      width: 15px;
      height: 15px;
    }
    .user-avatar {
      img {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }
}

.us-lang {
  @media (max-width: 1310px) {
    .dropdown-toggle {
      font-size: 0.93rem !important;
      padding: 5px 3px !important;
      letter-spacing: 0.7px;
    }
    .title-language {
      font-size: 0.95rem !important;
    }
  }

  @media (max-width: 1280px) {
    .dropdown-toggle {
      font-size: 0.8rem !important;
      padding: 5px 3px !important;
      letter-spacing: -0.48px;
    }
    .title-language {
      font-size: 0.7rem !important;
    }
    .text-md-left,
    .text-md-right {
      font-size: $medium-size;
    }
    .fullscreen {
      display: none;
    }
  }
  @media (max-width: 800px) {
    .dropdown-toggle {
      font-size: 0.7rem !important;
      padding: 5px 3px !important;
      letter-spacing: -0.48px;
    }
    .title-language {
      font-size: 0.7rem !important;
    }
    .text-md-left,
    .text-md-right {
      font-size: $medium-size;
    }
  }

  @media (max-width: 700px) {
    .dropdown-toggle {
      font-size: 0.5rem !important;
      padding: 5px 3px !important;
      letter-spacing: -0.48px;
    }
    .title-language {
      font-size: 0.7rem !important;
    }
    .text-md-left,
    .text-md-right {
      font-size: $medium-size;
    }
  }
}

@media (max-width: 800px) {
  .breadcrumb-item {
    font-size: $medium-size;
    padding-right: 0px;
  }
}

@media (max-width: 700px) {
  .breadcrumb-item {
    font-size: 8px;
    padding-right: 0px;
  }
}

// mobile version
@media only screen and (max-width: 600px) {
  @import './mobile.scss';
}

// tablet version
@media only screen and (max-width: 900px) and (min-width: 601px) {
  @import './tablet.scss';
}
