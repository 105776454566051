/*------------------------------------------------------------------
[Main Stylesheet]

Project:    LOGDY
Version:    1.1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    *. Login Layout 1 / .login-1
    *. Login Layout 2 / .login-2
    *. Login Layout 3 / .login-3
    *. Login Layout 4 / .login-4
    *. Login Layout 5 / .login-5
    *. Login Layout 6 / .login-6
    *. Login Layout 7 / .login-7
    *. Login Layout 8 / .login-8
    *. Login Layout 9 / .login-9
    *. Login Layout 10 / .login-10
    *. Login Layout 11 / .login-11
    *. Login Layout 12 / .login-12
    *. Login Layout 12 / .login-12
    *. Login inner form / .login-inner-form
    *. Login inner form details / .details
-------------------------------------------------------------------*/
/** GLOBAL CLASSES **/

body {
    font-family: 'Open Sans', sans-serif;
    color: #535353;
}

.img-fluid {
    max-width: 100% !important;
    height: auto;
}

.form-control:focus {
    box-shadow: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Open Sans', sans-serif;
}

/** Login 1 start **/
.login-1 {
    /* background: rgba(0, 0, 0, 0.04) url(../images/bg-image.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-1 .login-inner-form {
    max-width: 380px;
    margin: 0 auto;
    color: #717171;
    text-align: center;
}

.login-1 .login-inner-form .details {
    padding: 25px 30px 30px;
    background: #fff;
    border-radius: 5px 5px 0 0;
}

.login-1 .login-inner-form img {
    margin-bottom: 15px;
    height: 30px;
}

.login-1 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #717171;
}

.login-1 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-1 .login-inner-form .input-text {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #e8e7e7;
    outline: none;
    color: #717171;
    border-radius: 3px;
    height: 45px;
}

.login-1 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 12px 30px 11px 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
}

.login-1 .login-inner-form .social-list{
    margin-bottom: 0;
    padding: 0;
}

.login-1 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #717171;
    float: right;
}

.login-1 .login-inner-form .terms{
    margin-left: 3px;
}

.login-1 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-1 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-1 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-1 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-1 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
}

.login-1 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
}

.login-1 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #f0151f;
    border-color: #f0151f;
}

.login-1 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-1 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-1 .login-inner-form .social-list li {
    display: inline-block;
    font-size: 16px;
    margin-top: 30px;
}

.login-1 .login-inner-form .social-list li a {
    margin:2px;
    font-size: 14px;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
}

.login-1 .login-inner-form .footer {
    font-size: 14px;
    padding: 15px 20px;
    background-color: #f5f5f5;
    border-radius: 0 0 5px 5px;
    border-top: solid 1px #f5f5f5;
}

.login-1 .login-inner-form .footer span a {
    color: #717171;
}

.login-1 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-1 .login-inner-form .btn-theme:not([disabled]):not(.disabled).active, .btn-theme:not([disabled]):not(.disabled):active, .show > .btn-theme.dropdown-toggle {
    box-shadow: none;
}

.login-1 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-1 .login-inner-form .btn-theme {
    background: #f0151f;
    border: none;
    color: #fff;
}

.login-1 .login-inner-form .btn-theme:hover {
    background: #dc141d;
}

/** Social media **/
.login-1 .facebook-bg {
    background: #4867aa;
    color: #fff;
}

.login-1 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-1 .twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.login-1 .twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.login-1 .google-bg {
    background: #db4437;
    color: #fff;
}

.login-1 .google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

.login-1 .linkedin-bg {
    background: #0177b5;
    color: #fff;
}

.login-1 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-1 {
        padding: 30px 0;
    }
}
/** Login 1 end **/

/** Login 2 start **/
.login-2 .login-inner-form .col-pad-0{
    padding: 0;
}

.login-2 .bg-img{
    /* background: rgba(0, 0, 0, 0.04) url(../images/bg-image-6.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 100vh;
    text-align: right;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 50px 30px 40px;
}

.login-2 .bg-color{
    background: #0f96f9;
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-2 .login-inner-form {
    max-width: 350px;
    color: #fff;
    width: 100%;
    text-align: center;
}

.login-2 .login-inner-form p{
    color: #e4e4e4;
    margin-bottom: 0;
    font-size: 14px;
}

.login-2 .login-inner-form p a{
    color: #e4e4e4;
    font-weight: 500;
    margin-left: 4px;
}

.login-2 .login-inner-form img {
    margin-bottom: 15px;
    height: 30px;
}

.login-2 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
}

.login-2 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-2 .login-inner-form .input-text {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #e8e7e7;
    outline: none;
    color: #717171;
    border-radius: 3px;
    height: 45px;
}

.login-2 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-2 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-2 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-2 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-2 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
}

.login-2 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
}

.login-2 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #f0151f;
    border-color: #f0151f;
}

.login-2 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-2 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-2 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #fff;
    float: right;
}

.login-2 .login-inner-form .terms{
    margin-left: 4px;
}

.login-2 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 12px 30px 11px 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
}

.login-2 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-2 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-2 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-2 .login-inner-form .btn-theme {
    background: #f0151f;
    border: none;
    color: #fff;
}

.login-2 .login-inner-form .btn-theme:hover {
    background: #dc141d;
}

.login-2 .informeson {
    color: #fff;
}

.login-2 .informeson .link-btn{
    background: #fff;
    padding: 6px 30px;
    font-size: 13px;
    border-radius: 3px;
    margin: 3px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #f0151f;
}

.login-2 .informeson .active{
    background: #f0151f;
    color: #fff;
}

.login-2 .informeson .link-btn:hover{
    text-decoration: none;
}

.login-2 .informeson h3{
    color: #fbfbfb;
    margin-bottom: 25px;
    font-size: 31px;
}

.btn-section{
    margin-bottom: 30px;
}

.login-2 .informeson p{
    color: #fbfbfb;
    line-height: 30px;
    margin-bottom: 30px;
}

.login-2 .social-box ul{
    margin: 0;
    padding: 0;
}

.login-2 .social-box .social-list li {
    font-size: 16px;
    display: inline-block;
}

.login-2 .social-box .social-list li a {
    margin: 3px;
    font-size: 17px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
}

.login-2 .social-box{
    bottom: 40px;
    position: absolute;
    right: 45px;
}

.login-2 .none-2{
    display: none;
}

/** Social media **/
.login-2 .facebook-bg {
    background: #4867aa;
    color: #fff;
}

.login-2 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-2 .twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.login-2 .twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.login-2 .google-bg {
    background: #db4437;
    color: #fff;
}

.login-2 .google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

.login-2 .linkedin-bg {
    background: #0177b5;
    color: #fff;
}

.login-2 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-2 .none-2{
        display: inline-flex;
    }

    .login-2 .bg-color{
        padding: 30px 15px;
    }
}
/** Login 2 end **/

/** Login 3 start **/
.login-3 .login-inner-form .col-pad-0{
    padding: 0;
}

.login-3 .bg-img{
    background: rgba(0, 0, 0, 0.04) url(../images/bg-image-4.jpg) top left repeat;
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 100vh;
    text-align: left;
    z-index: 999;
    opacity: 1;
    border-radius: 100% 0 0 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
}

.login-3 .form-section{
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-3 .login-inner-form {
    max-width: 350px;
    color: #717171;
    width: 100%;
    text-align: center;
}

.login-3 .login-inner-form p{
    color: #717171;
    font-size: 14px;
    margin: 0;
}

.login-3 .login-inner-form p a{
    color: #717171;
    font-weight: 500;
}

.login-3 .login-inner-form img {
    margin-bottom: 15px;
    height:40px;
    width: auto;
}

.login-3 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #717171;
}

.login-3 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-3 .login-inner-form .input-text {
    outline: none;
    width: 100%;
    padding: 10px 20px;
    font-size: 13px;
    outline: 0;
    font-weight: 600;
    color: #717171;
    height: 45px;
    border-radius: 50px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-3 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 12px 30px 11px 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
}

.login-3 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-3 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-3 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-3 .login-inner-form .btn-theme {
    background: #333333;
    border: none;
    color: #fff;
}

.login-3 .login-inner-form .btn-theme:hover {
    background: #00a651;
}

.login-3 .login-inner-form .checkbox .terms {
    margin-left: 3px;
}

.login-3 .informeson {
    color: #fff;
    margin: 0 20px 0 70px;
}

.login-3 .informeson h3{
    color: #fff;
    margin-bottom: 20px;
    font-size: 25px;
}

.login-3 .informeson p{
    color: #fff;
    margin-bottom: 20px;
    line-height: 28px;
}

.login-3 .social-box .social-list{
    margin: 0;
    padding: 0;
    list-style: none;
}

.login-3 .social-box .social-list li {
    font-size: 16px;
    float: left;
}

.login-3 .social-box .social-list li a {
    margin-right: 20px;
    font-size: 25px;
    border-radius: 3px;
    display: inline-block;
    color: #fff;
}

.login-3 .none-2{
    display: none;
}

.login-3 .btn-section {
    margin-bottom: 30px;
}

.login-3 .informeson .link-btn:hover {
    text-decoration: none;
}

.login-3 .informeson .link-btn {
    background: #fff;
    padding: 6px 30px;
    font-size: 13px;
    border-radius: 3px;
    margin: 3px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #376bff;
}

.login-3 .informeson .active {
    background: #376bff;
    color: #fff;
}

.login-3 .login-inner-form .terms{
    margin-left: 3px;
}

.login-3 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-3 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-3 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-3 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-3 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
}

.login-3 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
}

.login-3 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #00a651;
    border-color: #00a651;
}

.login-3 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-3 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-3 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #717171;
    float: right;
}

/** Social media **/
.login-3 .facebook-color:hover {
    color: #4867aa!important;
}

.login-3 .twitter-color:hover {
    color: #33CCFF!important;
}

.login-3 .google-color:hover {
    color: #db4437!important;
}

.login-3 .linkedin-color:hover {
    color: #0177b5!important;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-3 .none-992 {
        display: none;
    }

    .login-3 .none-2{
        display: block;
    }

    .login-3 .form-section{
        padding: 30px 15px;
    }
}
/** Login 3 end **/

/** Login 4 start **/
.login-4 {
    background: rgba(0, 0, 0, 0.04) url(../images/bg-image-2.jpg) top left repeat;
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-4 .login-inner-form .col-pad-0{
    padding: 0;
}

.login-4 .btn-section{
    margin-bottom: 30px;
    display: inline-block;
}

.login-4 .link-btn {
    padding: 5px 30px;
    text-decoration: none;
    font-size: 13px;
    border-radius: 50px;
    margin: 0 3px 3px 0;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;
    border: 2px solid rgba(23, 23, 23, 0.72)
}

.login-4 .active {
    border: 2px solid rgba(23, 23, 23, 0.72);
    color: #fff;
    background: rgba(23, 23, 23, 0.72);
}

.login-4 .form-section {
    max-width: 350px;
    color: #fff;
    margin: 0 auto;
}

.login-4 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-4 .login-inner-form .input-text {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    color: #fff;
    border-radius: 3px;
    height: 40px;
    border: 1px solid rgba(23, 23, 23, 0.72);
    background: rgba(23, 23, 23, 0.72);
}

.login-4 .login-inner-form label{
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
    color: #efefef;
}

.login-4 .login-inner-form .checkbox {
    padding-left: 0;
    margin: 0 0 20px;
    font-size: 14px;
}

.login-4 .login-inner-form .checkbox a {
    color: #fff;
    font-size: 14px;
}

.login-4 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-4 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 30px 9px 30px;
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
}

.login-4 .login-inner-form p{
    color: #fff;
    font-size: 14px;
}

.login-4 .login-inner-form p a{
    color: #fff;
}

.login-4 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-4 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-4 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-4 .login-inner-form .btn-theme {
    background: #f0151f;
    border: none;
    color: #fff;
}

.login-4 .login-inner-form .btn-theme:hover {
    background: #dc141d;
}

.login-4 .tab-box .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    border: solid 2px rgba(23, 23, 23, 0.72);
    background: none;
    border-radius: 50px;
}

.login-4 .tab-box{
    display: inline-block;
}

.login-4 .nav-pills .nav-link {
    padding: 4px 30px;
    border: solid 2px transparent;
    font-size: 13px;
    margin: 0;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
}

.login-4 .nav-pills{
    margin-bottom: 20px;
}

.login-4 .login-inner-form .form-group.mb-35{
    margin-bottom: 35px;
}

.login-4 .login-inner-form .form-group.mb-30{
    margin-bottom: 30px;
}

/** MEDIA **/
@media (max-width: 768px) {
    .login-4 {
        padding: 30px 0;
    }
}
/** Login 4 end **/

/** Login 5 start **/
.login-5{
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.bg-grea-5{
    background: #797e8a;
}

.login-5 .login-box{
    max-width: 800px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin: 0 auto;
}

.login-5 .login-inner-form {
    color: #717171;
    text-align: center;
}

.login-5 .login-inner-form p{
    font-size: 14px;
    margin-bottom: 0;
}

.login-5 .col-pad-0{
    padding: 0;
}

.login-5 .login-inner-form .details p a{
    margin-left: 3px;
    color: #717171;
}

.login-5 .login-inner-form .details {
    padding:30px 80px;
}

.login-5 .bg-img {
    width: 100%;
    bottom: 0;
    text-align: center;
    padding: 100px 30px;
    z-index: 999;
    /* background: rgba(0, 0, 0, 0.04) url(../images/bg-image-5.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    opacity: 1;
}

.login-5 .btn-outline {
    border-radius: 50px;
    padding: 10px 50px 8px 50px;
    color: #fff;
    border: solid 1px #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
}

.login-5 .none-2{
    display: none;
}

.login-5 .btn-outline:hover{
    background: #fff;
    text-decoration: none;
    color: #f0151f;
}

.login-5 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #717171;
}

.login-5 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-5 .login-inner-form .input-text {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #eff0f2;
    background: #eff0f2;
    outline: none;
    color: #717171;
    border-radius: 3px;
    height: 40px;
}

.login-5 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 50px 8px 50px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
    text-transform: uppercase;
}

.login-5 .bg-img .social-list li {
    display: inline-block;
    font-size: 16px;
}

.login-5 .bg-img h3{
    font-size: 27px;
    color: #fbfbfb;
    margin-bottom: 20px;
}

.login-5 .bg-img .logo{
    height: 30px;
    margin-bottom: 15px;
}

.login-5 .bg-img p{
    font-size: 16px;
    color: #fbfbfb;
    margin-bottom: 37px;
}

.login-5 .bg-img .btn-sm{
    padding: 6px 20px 6px 20px;
    font-size: 13px;
}

.login-5 .bg-img .social-list{
    margin: 35px 0 0 0;
    padding: 0;
}

.login-5 .bg-img .social-list li a {
    margin: 0 3px;
    font-size: 15px;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
}

.login-5 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-5 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-5 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-5 .login-inner-form .btn-theme {
    background: #f0151f;
    border: none;
    color: #fff;
}

.login-5 .login-inner-form .btn-theme:hover {
    background: #dc141d;
}

.login-5 .login-inner-form .terms{
    margin-left: 3px;
}

.login-5 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-5 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-5 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-5 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-5 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #eff0f2;
    border-radius: 3px;
    background-color: #eff0f2;
}

.login-5 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
}

.login-5 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #f0151f;
    border-color: #f0151f;
}

.login-5 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-5 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-5 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #717171;
    float: right;
}

/** Social media **/
.login-5 .facebook-bg {
    background: #4867aa;
    color: #fff;
}

.login-5 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-5 .twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.login-5 .twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.login-5 .google-bg {
    background: #db4437;
    color: #fff;
}

.login-5 .google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

.login-5 .linkedin-bg {
    background: #0177b5;
    color: #fff;
}

.login-5 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-box{
        max-width: 400px;
        margin: 0 auto;
    }

    .login-5 .login-inner-form .details {
        padding: 30px;
    }

    .login-5 .login-box {
        max-width: 400px;
    }

    .login-5 .btn-theme{
        display: block;
        width: 100%;
    }

    .login-5 .none-2 {
        display: block;
    }

    .none-992{
        display: none;
    }

    .login-5 {
        padding: 30px 0;
    }
}
/** Login 5 end **/

/** Login 6 start **/
.login-6{
    background: #f5f5f5;
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-6 .login-inner-form {
    color: #717171;
    text-align: center;
}

.login-6 .col-pad-0{
    padding: 0;
}

.login-box-6{
    margin: 0 150px 0 200px;
    max-width: 700px;
    border-radius: 10px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    background: #fff;
}

.login-6 .login-inner-form .details p a{
    margin-left: 3px;
    color: #717171;
}

.login-6 .login-inner-form .details p{
    margin-bottom: 0;
    font-size: 14px;
}

.login-6 .login-inner-form .details {
    padding:30px 60px 30px 0;
}

.login-6 .bg-img {
    background-size: cover;
    width: 100%;
    bottom: 0;
    border-radius: 10px;
    padding: 80px 30px;
    background: #0f3f6f;
    margin: 30px 0;
    left: -60px;
    z-index: 999;
}

.login-6 .btn-outline {
    border-radius: 3px;
    padding: 6px 30px 6px 30px;
    color: #fff;
    background: #1b548c;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
}

.login-6 .none-2{
    display: none;
}

.login-6 .btn-outline:hover{
    background: #fff;
    text-decoration: none;
    color: #1b548c;
}

.login-6 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #717171;
}

.login-6 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-6 .login-inner-form .input-text {
    outline: none;
    width: 100%;
    padding: 10px 20px;
    font-size: 15px;
    outline: 0;
    font-weight: 500;
    color: #717171;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-6 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 50px 8px 50px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    height: 45px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    text-transform: uppercase;
}

.login-6 .bg-img .social-list li {
    display: inline-block;
    font-size: 16px;
}

.login-6 .bg-img .logo{
    height: 30px;
    margin-bottom: 20px;
}

.login-6 .bg-img p{
    font-size: 15px;
    color: #e8e8e8;
    margin-bottom: 30px;
}

.login-6 .bg-img .btn-sm{
    padding: 6px 20px 6px 20px;
    font-size: 13px;
}

.login-6 .bg-img .social-list{
    padding: 0;
    margin:35px 0 0;
}

.login-6 .bg-img .social-list li a {
    margin: 0 5px;
    font-size: 20px;
    color: #1b548c;
    border-radius: 3px;
    display: inline-block;
}

.login-6 .bg-img .social-list li a:hover{
    color: #fff;
}

.login-6 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-6 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-6 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-6 .login-inner-form .btn-theme {
    background: #1b548c;
    border: none;
    color: #fff;
}

.login-6 .login-inner-form .btn-theme:hover {
    background: #194c7d;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-6 .login-inner-form .terms{
    margin-left: 3px;
}

.login-6 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-6 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-6 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-6 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-6 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
}

.login-6 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
}

.login-6 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #1b548c;
    border-color: #1b548c;
}

.login-6 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-6 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-6 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #717171;
    float: right;
}

/** MEDIA **/
@media (max-width: 1200px) {
    .login-box-6 {
        margin: 0 70px 0 120px;
    }
}

@media (max-width: 992px) {
    .none-992{
        display: none;
    }

    .login-box-6 {
        margin: 0 auto;
        max-width: 400px;
    }

    .login-6 .login-inner-form .details {
        padding: 30px;
    }

    .login-6 .pad-0{
        padding: 0;
    }
}
/** Login 6 end **/

/** Login 7 start **/
.login-7 .login-inner-form .col-pad-0{
    padding: 0;
}

.login-7 .bg-img{
    background-size: cover;
    top: 0;
    width: 100%;
    background: #151a22;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
}

.login-7 .bg-color-7{
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 70px;
}

.login-7 .login-inner-form{
    width: 100%;
}

.login-7 .btn-theme {
    border-radius: 3px;
    padding: 12px 30px 11px 30px;
    color: #fff;
    background: #00a875;
    letter-spacing: 1px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.login-7 .btn-theme:hover{
    background: #fff;
    color: #00a875;
    text-decoration: none;
}

.login-7 .login-inner-form.form-2{
    margin: 10% auto 30px;
}

.login-7 .login-inner-form .forgot{
   line-height: 45px;
    float: right;
    color: #717171;
}

.login-7 .login-inner-form p{
    color: #717171;
    margin-bottom: 0;
    font-size: 14px;
}

.login-7 .login-inner-form p a{
    color: #717171;
    font-weight: 500;
}
.login-7 .login-inner-form .thembo{
    margin-left: 4px;
}

.login-7 .login-inner-form h3 {
    margin: 0 0 50px;
    font-size: 25px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #717171;
}

.login-7 .login-inner-form .form-group {
    margin-bottom: 40px;
}

.login-7 .login-inner-form .input-text {
    width: 100%;
    padding: 0 0 10px;
    font-size: 15px;
    font-weight: 400;
    background: transparent;
    border: none;
    border-bottom: 2px solid #e8e7e7;
    outline: none;
    color: #717171;
}

.login-7 .login-inner-form .checkbox {
    padding-left: 0;
    margin: 0 0 20px;
    font-size: 14px;
}

.login-7 .login-inner-form .checkbox a {
    font-size: 14px;
}

.login-7 .login-inner-form .checkbox .terms{
    margin-left: 3px;
}

.login-7 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 12px 30px 11px 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
}

.login-7 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-7 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-7 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-7 .login-inner-form .btn-theme {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: none;
    color: #00a875;
}

.login-7 .login-inner-form .btn-theme:hover {
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
}

.login-7 .informeson {
    color: #fff;
    margin: 0 35px 0;
}

.login-7 .informeson h3{
    margin-bottom: 30px;
    font-size: 40px;
    color: #bdbdbd;
}

.login-7 .informeson p{
    margin-bottom: 40px;
    font-size: 17px;
    color: #676767;
}

.login-7 .social-box ul{
    margin: 0;
    padding: 0;
}

.login-7 .social-box .social-list li {
    font-size: 16px;
    display: inline-block;
}

.login-7 .social-box .social-list li a {
    margin: 0 20px 5px 0;
    font-size: 17px;
    display: inline-block;
    color: #676767;
    text-decoration: none;
}

.login-7 .social-box .social-list li a:hover{
    color: #505050;
}

.login-7 .social-box{
    bottom: 40px;
    position: absolute;
    left: 65px;
}

.login-7 .none-2{
    display: none;
}

.login-7 .logo{
    top: 40px;
    position: absolute;
    left: 65px;
}

.login-7 .logo img{
    height: 30px;
}

.login-7 .logo-2 {
    display: none;
    height: 40px;
    margin-bottom: 15px;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-7 .none-2{
        display: block;
    }

    .login-7 .login-inner-form h3 {
        font-size: 22px;
    }

    .login-7 .login-inner-form{
        max-width: 400px;
        padding: 30px;
        background: #fff;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    }

    .none-992{
        display: none!important;
    }

    .login-7 .login-inner-form h3 {
        margin: 0 0 40px;
    }

    .login-7 .bg-color-7 {
        padding: 30px 15px;
    }
}
/** Login 7 end **/


/** Login 8 start **/
.login-8 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
}

.login-8 .login-inner-form {
    max-width: 400px;
    margin: 0 auto;
}

.login-8 .login-inner-form .details{
    padding: 70px 30px 30px;
    background: #fff;
    max-width: 400px;
    margin: 0 auto 15px;
    border-radius: 5px;
}

.bg-color-8{
    background: #f7f5f5;
}

.login-8 .login-inner-form p{
    color: #717171;
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
}

.login-8 .login-inner-form p a{
    color: #717171;
    font-weight: 500;
}

.login-8 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 30px 0 25px;
    text-align: center;
    position: relative;
}

.login-8 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-8 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #afafaf;
    text-transform: capitalize;
}

.login-8 .login-inner-form ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.login-8 .login-inner-form .social-list li {
    display: inline-block;
}

.login-8 .login-inner-form .social-list li a {
    margin: 1px;
    font-size: 12px;
    font-weight: 600;
    width: 90px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.login-8 .login-inner-form .thembo{
    margin-left: 4px;
}

.login-8 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #717171;
    text-align: center;
}

.login-8 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-8 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-8 .login-inner-form .form-box input {
    float: left;
    width: 100%;
    padding: 11px 15px 11px 45px;
}

.login-8 .login-inner-form .input-text {
    font-size: 14px;
    outline: none;
    color: #717171;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-8 .login-inner-form .form-box i {
    position: absolute;
    top: 8px;
    left: 15px;
    font-size: 19px;
}

.login-8 .login-inner-form .checkbox a {
    font-size: 14px;
}

.login-8 .login-inner-form .checkbox .terms{
    margin-left: 3px;
}

.login-8 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 12px 40px 11px 40px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
}

.login-8 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-8 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-8 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-8 .login-inner-form .btn-theme {
    background: #00a875;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: none;
    color: #fff;
}

.login-8 .login-inner-form .btn-theme:hover {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
}

.login-8 .none-2{
    display: none;
}

.login-8 .logo{
    top: 40px;
    position: absolute;
    left: 65px;
}

.login-8 .logo-2 {
    text-align: center;
    max-width: 150px;
    margin: 0 auto -35px;
}

.login-8 .logo-2 img{
    background: #00a875;
    padding: 20px;
    width: 150px;
    height: 70px;
    border-radius: 5px;
}

.login-8 .login-inner-form .terms{
    margin-left: 3px;
}

.login-8 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-8 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-8 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-8 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-8 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.login-8 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
    color: #717171;
}

.login-8 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #00a875;
    border-color: #00a875;
}

.login-8 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-8 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-8 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #717171;
    float: right;
}

/** Social media **/
.login-8 .facebook-bg {
    background: #4867aa;
    color: #fff;
}

.login-8 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-8 .twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.login-8 .twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.login-8 .google-bg {
    background: #db4437;
    color: #fff;
}

.login-8 .google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-8 {
        padding: 30px 0;
    }
}
/** Login 8 end **/

/** Login 9 start **/
.login-9 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
}

.login-9 .login-inner-form {
    color: #717171;
    text-align: center;
    position: relative;
}

.login-9 .form-section{
    max-width: 400px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 40px;
    border: 1px solid #e8e7e7;
}

.login-9 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-9 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-9 .login-inner-form .input-text {
    font-size: 14px;
    border: 1px solid #e8e7e7;
    outline: none;
    color: #717171;
    border-radius: 50px;
}

.login-9 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-9 .login-inner-form .form-box input {
    float: left;
    width: 100%;
    padding: 11px 50px 11px 25px;
}

.login-9 .login-inner-form .form-box i {
    position: absolute;
    top: 8px;
    right: 25px;
    font-size: 19px;
}

.login-9 .login-inner-form label{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-9 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-9 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 30px 9px 30px;
    height: 45px;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
}

.login-9 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-9 .login-inner-form p{
    margin: 0;
    color: #717171;
}

.login-9 .login-inner-form p a{
    color: #717171;
}

.login-9 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-9 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-9 .login-inner-form .btn-theme {
    background: #50a1ff;
    border: none;
    color: #fff;
}

.login-9 .login-inner-form .btn-theme:hover {
    background: #4595f1;
}

.login-9 .logo{
    text-align: center;
    margin-bottom: 25px;
}

.login-9 .logo img{
    height: 30px;
}

.login-9 .nav-pills li{
    display: inline-block;
}

.login-9 .login-inner-form .form-group.mb-35{
    margin-bottom: 35px;
}

.login-9 .login-inner-form .form-group.mb-30{
    margin-bottom: 30px;
}

.login-9 .login-inner-form .social-list li {
    display: inline-block;
}

.login-9 .login-inner-form .social-list li a {
    margin: 2px;
    font-size: 15px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
}

.login-9 .login-inner-form .social-list li a:hover{
    text-decoration: none;
    color: #fff;
}

.login-9 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 30px 0 28px;
    text-align: center;
    position: relative;
}

.login-9 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-9 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #afafaf;
    text-transform: capitalize;
}

.login-9 .form-section p{
    margin: 30px 0 0;
    font-size: 14px;
}

.login-9 .form-section p a{
    color: #717171;
}

.login-9 .btn-section{
    text-align: center;
}

.login-9 .btn-section .link-btn{
    padding: 4px 20px;
    font-size: 14px;
    border: solid 1px #717171;
    margin-right: 5px;
    letter-spacing: 1px;
    border-radius: 50px;
    font-weight: 400;
    color: #717171;
    text-decoration: none;
    text-decoration: blink;
}

.login-9 .btn-section .active {
    border: solid 1px #50a1ff;
    background: transparent;
    color: #50a1ff;
}

.login-9 .login-inner-form ul{
    margin: 0;
    padding: 0;
}

.login-9 .login-inner-form .terms{
    margin-left: 3px;
}

.login-9 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-9 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-9 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-9 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-9 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.login-9 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
    color: #717171;
}

.login-9 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #50a1ff;
    border-color: #50a1ff;
}

.login-9 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-9 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-9 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #717171;
    float: right;
}

.login-9 .facebook-bg {
    border: solid 2px #4867aa;
    color: #4867aa;
}

.login-9 .facebook-bg:hover{
    background: #4867aa;
}

.login-9 .twitter-bg {
    border: solid 2px #33CCFF;
    color: #33CCFF;
}

.login-9 .twitter-bg:hover {
    background: #33CCFF;
}

.login-9 .google-bg {
    border: solid 2px #db4437;
    color: #db4437;
}

.login-9 .google-bg:hover {
    background: #db4437;
}

.login-9 .linkedin-bg {
    border: solid 2px #0177b5;
    color: #0177b5;
}

.login-9 .linkedin-bg:hover {
    background: #0177b5;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-9 .form-section {
        padding: 30px;
    }

    .login-9 {
        padding: 30px 0;
    }
}

/** Login 10 start **/
.login-10 .login-inner-form {
    color: #cccccc;
    text-align: center;
    position: relative;
}

.login-10 .bg-color-10 {
    background: #151922;
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
}

.login-10 .form-section{
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
}

.login-10 .form-box{
    width: 100%;
}

.login-10 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-10 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-10 .login-inner-form .input-text {
    font-size: 14px;
    outline: none;
    color: #717171;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-10 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-10 .login-inner-form .form-box input {
    float: left;
    width: 100%;
    padding: 11px 40px 11px 15px;
}

.login-10 .login-inner-form .form-box i {
    position: absolute;
    top: 8px;
    right: 15px;
    font-size: 19px;
    color: #717171;
}

.login-10 .info {
    color: #fff;
    margin: 0 100px;
}

.login-10 .bg-img {
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    /* background: rgba(0, 0, 0, 0.04) url(../images/bg-image.jpg) top left repeat; */
    background-size: cover;
    opacity: 1;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-10 .info h1{
    margin-bottom: 25px;
    font-size: 60px;
    color: #2b2b2b;
}

.login-10 .info P{
    color: #2b2b2b;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
}

.login-10 .login-inner-form label{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-10 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-10 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 30px 9px 30px;
    height: 45px;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    color: #d6d6d6;
}

.login-10 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-10 .login-inner-form p{
    margin: 0;
    color: #717171;
}

.login-10 .login-inner-form p a{
    color: #717171;
}

.login-10 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-10 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-10 .login-inner-form .btn-theme {
    background: #2a3148;
    border: none;
    color: #fff;
}

.login-10 .login-inner-form .btn-theme:hover {
    background: #36405f;
}

.login-10 .logo{
    text-align: center;
    margin-bottom: 30px;
}

.login-10 .logo img{
    height: 30px;
}

.login-10 .nav-pills li{
    display: inline-block;
}

.login-10 .login-inner-form .form-group.mb-35{
    margin-bottom: 35px;
}

.login-10 .login-inner-form .form-group.mb-30{
    margin-bottom: 30px;
}

.login-10 .login-inner-form .social-list{
    padding: 0;
}

.login-10 .login-inner-form .social-list li {
    display: inline-block;
}

.login-10 .login-inner-form .social-list li a {
    margin: 1px;
    font-size: 12px;
    font-weight: 600;
    width: 90px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-10 .login-inner-form .social-list li a:hover{
    text-decoration: none;
}

.login-10 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 30px 0;
    text-align: center;
    position: relative;
}

.login-10 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #383e50;
    content: "";
}

.login-10 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #151822;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #afafaf;
    text-transform: capitalize;
}

.login-10 .login-inner-form .terms{
    margin-left: 3px;
}

.login-10 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-10 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-10 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-10 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-10 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.login-10 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
    color: #d6d6d6;
}

.login-10 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #2a3148;
    border-color: #2a3148;
}

.login-10 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-10 .btn-section .link-btn {
    padding: 5px 20px;
    font-size: 13px;
    border: solid 2px #2a3148;
    background: transparent;
    margin-right: 5px;
    letter-spacing: 1px;
    border-radius: 3px;
    font-weight: 400;
    color: #d6d6d6;
    text-decoration: none;
    text-decoration: blink;
}

.login-10 .btn-section .active {
    border: solid 2px #2a3148;
    background: #2a3148;
}

.login-10 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-10 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #d6d6d6;
    float: right;
}

.login-10 .form-section p {
    margin: 28px 0 0;
    font-size: 14px;
    color: #d6d6d6;
}

.login-10 .form-section p a {
    color: #d6d6d6;
}

.login-10 .facebook-bg {
    background: #4867aa;
}

.login-10 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-10 .twitter-bg {
    background: #33CCFF;
}

.login-10 .twitter-bg:hover {
    background: #56d7fe;
}

.login-10 .google-bg {
    background: #db4437;
}

.login-10 .google-bg:hover {
    background: #dc4e41;
}

/** MEDIA **/
@media (max-width: 1200px) {
    .login-10 .info h1 {
        font-size: 40px;
    }

    .login-10 .info {
        margin: 0 70px;
    }
}

@media (max-width: 992px) {
    .login-10 .none-992{
        display: none;
    }

    .login-10 .bg-color-10 {
        padding: 30px 15px;
    }

}

/** Login 10 start **/

/** Login 10 start **/
.login-11 .login-inner-form {
    color: #cccccc;
    position: relative;
}

.login-11 .bg-color-11 {
    min-height: 100vh;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
}

.login-11 .form-section{
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
}

.login-11 .form-box{
    width: 100%;
}

.login-11 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-11 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-11 .login-inner-form .input-text {
    font-size: 14px;
    outline: none;
    color: #717171;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-11 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-11 .login-inner-form .form-box input {
    float: left;
    width: 100%;
    padding: 11px 15px 11px 60px;
}

.login-11 .login-inner-form .form-box i {
    position: absolute;
    left: 0;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #dbdbdb;
    border-radius: 3px 0 0 3px;
    font-size: 18px;
    color: #50a1ff;
    font-weight: 600;
}

.login-11 .info {
    color: #fff;
    margin: 0 100px;
}

.login-11 .bg-img {
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: 999;
    background: #50a1fc;
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-11 .info h1{
    color: #fff;
    margin-bottom: 25px;
    font-size: 60px;
}

.login-11 .info P{
    color: #fff;
    font-size: 17px;
    line-height: 30px;
}

.login-11 .login-inner-form label{
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
    text-align: left;
    color: #717171;
}

.login-11 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-11 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 30px 9px 30px;
    height: 45px;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    color: #fff;
}

.login-11 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 0;
}

.login-11 .login-inner-form p{
    margin: 0;
    color: #717171;
}

.login-11 .login-inner-form p a{
    color: #717171;
}

.login-11 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-10 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-11 .login-inner-form .btn-theme {
    background: #50a1fc;
    border: none;
    color: #fff;
}

.login-11 .login-inner-form .btn-theme:hover {
    background: #4595f1;
}

.login-11 .logo{
    text-align: center;
    margin-bottom: 15px;
}

.login-11 .logo img{
    height: 30px;
}

.login-11 h3{
    text-align: center;
    margin: 0 0 30px;
    font-size: 22px;
}

.login-11 .nav-pills li{
    display: inline-block;
}

.login-11 .login-inner-form .form-group.mb-35{
    margin-bottom: 35px;
}

.login-11 .login-inner-form .form-group.mb-30{
    margin-bottom: 30px;
}

.login-11 .login-inner-form .social-list{
    padding: 0;
    text-align: center;
}

.login-11 .login-inner-form .social-list li {
    display: inline-block;
}

.login-11 .login-inner-form .social-list li a {
    margin: 1px;
    font-size: 12px;
    font-weight: 600;
    width: 90px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-11 .login-inner-form .social-list li a:hover{
    text-decoration: none;
}

.login-11 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 20px 0 15px;
    text-align: center;
    position: relative;
}

.login-11 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #e0e4ef;
    content: "";
}

.login-11 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #afafaf;
    text-transform: capitalize;
}

.login-11 .login-inner-form .terms{
    margin-left: 3px;
}

.login-11 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-11 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-11 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-11 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-11 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.login-11 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
    color: #717171;
}

.login-11 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #50a1fc;
    border-color: #50a1fc;
}

.login-11 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-11 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-11 .login-inner-form .checkbox a {
    font-size: 14px;
    font-weight: 600;
    color: #717171;
    float: right;
}

.login-11 .form-section p {
    margin: 28px 0 0;
    font-size: 14px;
    color: #717171;
    font-weight: 600;
}

.login-11 .form-section p a {
    color: #717171;
}

.login-11 .facebook-bg {
    background: #4867aa;
}

.login-11 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-11 .twitter-bg {
    background: #33CCFF;
}

.login-11 .twitter-bg:hover {
    background: #56d7fe;
}

.login-11 .google-bg {
    background: #db4437;
}

.login-11 .google-bg:hover {
    background: #dc4e41;
}

/** MEDIA **/
@media (max-width: 1200px) {
    .login-11 .info {
        margin: 0 70px;
    }

    .login-11 .info h1 {
        font-size: 45px;
    }
}

@media (max-width: 992px) {
    .login-11 .none-992{
        display: none;
    }

    .login-11 .bg-color-11 {
        padding: 20px 15px;
    }

}
/** Login 11 start **/

/** Login 12 start **/
.login-12{
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background: #151a22;
}

.login-12 .login-inner-form {
    color: #272323;
    text-align: center;
}

.login-12 .col-pad-0{
    padding: 0;
}

.login-12 .login-inner-form .details p{
    color: #403838;
    font-weight: 400;
    font-size: 15px;
}

.login-12 .login-inner-form .details p a{
    margin-left: 3px;
    color: #403838;
}

.login-12 .login-inner-form .details p{
    margin-bottom: 0;
}

.login-12 .login-inner-form .details {
    padding:30px 0 30px 60px;
}

.login-12 .bg-img {
    background-size: cover;
    width: 100%;
    bottom: 0;
    border-radius: 10px;
    padding: 80px 20px;
    background: #ff574d;
    margin: 30px 0;
    right: -60px;
    z-index: 999;
}

.login-box-12 {
    margin: 0 200px 0 150px;
    max-width: 700px;
    border-radius: 10px;
    /* background: rgba(0, 0, 0, 0.04) url(../images/bg-image.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
    text-align: center;
}

.login-12 .btn-outline {
    border-radius: 3px;
    padding: 6px 30px 6px 30px;
    color: #ff574d;
    background: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
}

.login-12 .none-2{
    display: none;
}

.login-12 .btn-outline:hover{
    background: #ecebeb;
    text-decoration: none;
}

.login-12 .login-inner-form h3 {
    margin: 0 0 25px;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #272323;
}

.login-12 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-12 .login-inner-form .input-text {
    outline: none;
    width: 100%;
    padding: 10px 20px;
    font-size: 15px;
    outline: 0;
    font-weight: 500;
    color: #717171;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-12 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 10px 50px 8px 50px;
    height: 45px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
}

.login-12 .bg-img .social-list li {
    display: inline-block;
    font-size: 16px;
}

.login-12 .bg-img .logo{
    height: 30px;
    margin-bottom: 20px;
}

.login-12 .bg-img p{
    font-size: 15px;
    color: #fff;
    margin-bottom: 30px;
}

.login-12 .bg-img .btn-sm{
    padding: 6px 20px 6px 20px;
    font-size: 13px;
}

.login-12 .bg-img .social-list{
    margin-top: 35px;
    padding: 0;
}

.login-12 .bg-img .social-list li a {
    margin: 0 5px;
    font-size: 20px;
    color: #fff;
    border-radius: 3px;
    display: inline-block;
}

.login-12 .bg-img .social-list li a:hover{
    color: #ecebeb;
}

.login-12 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-12 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-12 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-12 .login-inner-form .btn-theme {
    background: #ff574d;
    border: none;
    color: #fff;
}

.login-12 .login-inner-form .btn-theme:hover {
    background: #ec4a40;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-12 .login-inner-form .terms{
    margin-left: 3px;
}

.login-12 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-12 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-12 .login-inner-form .form-check a {
    color: #717171;
    float: right;
}

.login-12 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-12 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.login-12 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 14px;
    color: #403838;
}

.login-12 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #ff574d;
    border-color: #ff574d;
}

.login-12 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "\2713";
}

.login-12 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-12 .login-inner-form .checkbox a {
    font-size: 14px;
    color: #403838;
    float: right;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-12 .none-992{
        display: none;
    }

    .login-12 .pad-0{
        padding: 0;
    }

    .login-12 .login-box-12 {
        margin: 0 auto;
        max-width: 400px;
    }

    .login-12 .login-inner-form .details {
        padding: 30px;
    }
}
/** Login 11 end **/

/** MEDIA **/
@media (max-width: 1200px) {  }

@media (max-width: 992px) {  }

@media (max-width: 768px) {  }

@media (max-width: 580px) {  }

@media (min-width: 991px) {  }

@media (min-width: 991px) and (max-width: 1200px) {  }