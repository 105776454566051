.btn-collapsible-index {
  background-color: #ffffff;
  border: 1px solid #d6d6d6 !important;
  // border-color: #d6d6d6;
  border-radius: 10px !important;
  color: #3f3f3f;

  &.active-main {
    color: #28a745;
    border-color: #28a745 !important;
  }
  i {
    float: right;
    background-color: #3f3f3f;
    color: #ffffff;
    font-size: 0.9rem;
    float: right;
    border-radius: 50%;
    padding: 3px 3px 2px 4px;
    // margin-right: 20px;
  }
}

.revenue-siwtch {
  margin-top: 2px;
}

.check-group-item {
  .check-item {
    margin-top: 15px;
  }
}

.component-group {
  width: 85vw;
}

.component-btn-group {
  padding-right: 40px;
  padding-left: 18px;
  padding-bottom: 20px;
  padding-top: 20px;
}

@media only screen and (max-width: 1485px) and (min-width: 1250px) {
  .component-group {
    width: 80vw;
  }
}
@media only screen and (max-width: 1249px) and (min-width: 1000px) {
  .component-group {
    width: 70vw;
  }
}
