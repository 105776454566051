.btn-with-ico {
  color: #28a745;
}

.title-select-operator {
  margin: auto 20px;
}

.btn-operator {
  border-radius: 6px;
  border: none;
  width: 35px;
  height: 35px;
  -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.14);
}

.btn-operator:hover {
  background: #28a745;
  color: white;
}

.btn-operator {
  &.active {
    background: #28a745;
    color: white;
  }
}

.btn-add-operator {
  margin: auto 30px;
  height: 30px;
}

.data-exp {
  margin: auto 0px;
}

.icon-check {
  transition: opacity 0.8s, transform 0.8s;
  transform: rotateY(-360deg);
}

.icon-edit {
  transition: opacity 0.8s, transform 0.8s;
  transform: rotateY(-0deg);
}

.modal-stream-details {
  .modal-content {
    width: 108%;
  }
  .modal-body {
    padding: 0px 35px 0px 0px;
  }
}

.item-drag-parent {
  text-align: center;
  font-size: 20px;
}
