$color-border-input-error: #ed1c24;
$color-border-input-success: #28a745;

.content-wrapper {
  min-height: calc(100vh - 4.7rem);
}
.field-none {
  display: none;
}

.buttons-attibute {
  padding: 10px;
}

.form-require {
  input {
    border: 1px solid $color-border-input-error;
  }
  .form-control:focus {
    border: 1px solid $color-border-input-error;
  }
}

.form-success {
  input {
    border: 1px solid $color-border-input-success;
  }
  .form-control:focus {
    border: 1px solid $color-border-input-success;
  }
}
.payment-rigth {
  float: right;
  padding: 0px;
}

.btn-credit-card {
  height: 28px;
  margin: 25px 10px 0px;
  padding: 2px;
  font-size: 12px !important;
}

.btn-account {
  padding: 20px 15px 30px 0px;
}

.detail-phone {
  margin: 0px 0px 0px auto;
}

.label-switch {
  margin-left: 10px;
  cursor: pointer;
}

.switch-group {
  padding: 15px 0px 20px 0px;
  margin-left: 7px;
}

.invoice-page {
  background: none;
}

.group-btn-invoice {
  padding-bottom: 10px;
}

.btn-subsciption {
  padding-top: 10px;
  padding-bottom: 10px;
  // min-width: 900px;
}

.parent-account {
  padding-top: 25px;
}

.attribute-select {
  .react-select .custom-react-select__indicator {
    padding: 0px !important;
  }
}

.customer-new {
  .card-statistics {
    border: none;
    border-radius: none !important;
    box-shadow: none;
  }
}

.group-info-card {
  padding-bottom: 10px !important;

  &.last-child {
    padding-right: 0px;
  }

  .icon-info {
    padding: 10px !important;
  }
}