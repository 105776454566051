.collection-form-item{
  margin-top: 0px !important;
}

.collection-dropdown {
  .css-26l3qy-menu {
    z-index: 2 !important;
  }
}

.collection-form-history{
  margin-left: 15px;
  .clearfix{
    margin: auto;
  }
}
